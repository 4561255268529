<template>
  <v-card elevation="0" max-width="500" rounded="lg" class="pa-12 pb-10 width100 otp">
    <div class="primary--text text--darken-2 mb-10 link" @click="back">
      <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
    </div>
    <div class="f28 font-weight-bold mb-1">Second-factor authentication</div>
    <div class="text--text mb-7 f18 font-weight-medium">Please enter OTP from your 2FA application or device</div>
    <v-form @submit.prevent="requestOtp" ref="form" lazy-validation>
      <v-text-field
        class="field48"
        v-model="otp"
        placeholder="OTP code"
        :error-messages="otpErrors"
        outlined
        dense
        required
        color="primary"
        height="48"
      ></v-text-field>
      <div class="text-center">
        <v-btn depressed height="48" type="submit" block large color="primary">
          <span class="f15 font-weight-bold">Continue</span>
        </v-btn>
        <v-btn to="/sign-in/otp-recovery" class="mt-11" height="20" block text color="primary darken-2">
          <span class="f15 text-600">Cannot get OTP?</span>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      otp: '',
      error: [],
    };
  },
  methods: {
    back() {
      this.$router.push('/sign-in');
    },
    async requestOtp() {
      this.error = [];
      const data = new Object();
      data.code = this.otp;
      await this.$store
        .dispatch('otp', data)
        .then((res) => {
          sessionStorage.setItem('jwt_token', res.data.access_token);
          this.$router.push(this.$route.query.from || '/');
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'email__not_confirmed')) {
        this.$router.push('/email-not-confirmed');
      } else if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/account-suspended');
      } else if (this.error.find((item) => item == 'action__limit_exceeded')) {
        this.$router.push('/sign-in-tries-limit');
      }
    },
  },
  computed: {
    otpErrors() {
      const errors = [];
      this.error.find((item) => item == 'code__required') && errors.push('Please enter your OTP code');
      this.error.find((item) => item == 'code__invalid') && errors.push('OTP code is not correct');
      this.error.find((item) => item == 'otp__required') && errors.push('Please enter your OTP code');
      this.error.find((item) => item == 'otp__invalid') && errors.push('OTP code is not correct');
      return errors;
    },
  },
};
</script>
